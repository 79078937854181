<template>
  <YLayoutBodyMenu>
    <el-card style="min-height: 45rem">
      <el-form label-width="10rem" style="padding: 2rem;">
        <el-form-item label="广告类型">
          <el-radio-group @change="adTypeChange" v-model="form.ad_type">
            <el-radio v-for="(item,key) in adType" :key="key" :label="item.value">{{item.label}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="素材">
          <y_upload_img v-if="form.ad_type === adType.image.value" v-model="form.content"></y_upload_img>
          <y_upload_video v-if="form.ad_type === adType.video.value" v-model="form.content"></y_upload_video>
          <y_editor v-if="form.ad_type === adType.popupRichText.value" v-model="form.content"></y_editor>
        </el-form-item>
        <el-form-item label="视频展示高度" v-if="form.ad_type === adType.video.value">
          <el-input-number v-model="form.video_height"></el-input-number>
          <div class="y-desc">默认展示 宽600 * 高600</div>
          <div class="y-desc">此处可根据自身视频自行调整高度数值 = 600 / 视频宽 * 视频高</div>
        </el-form-item>
        <el-form-item label="跳转链接" v-if="form.ad_type === adType.image.value">
          <y_choose_link v-model="form.link"></y_choose_link>
        </el-form-item>
        <template v-if="form.ad_type === adType.popupRichText.value">
          <el-form-item label="查看详情播放视频">
            <el-switch v-model="form.popup_detail_use_video"></el-switch>
            <span>开启时全屏播放视频，关闭时如配置链接将跳转连接</span>
          </el-form-item>
          <el-form-item label="查看详情连接">
            <y_choose_link v-model="form.popup_detail_link"></y_choose_link>
          </el-form-item>
          <el-form-item label="查看详情视频">
            <y_upload_video v-model="form.popup_detail_video"></y_upload_video>
          </el-form-item>
        </template>
        <el-form-item label="启用">
          <el-switch v-model="form.enable"></el-switch>
        </el-form-item>
        <el-form-item>
          <el-button @click="submit" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </YLayoutBodyMenu>
</template>

<script>
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_upload_video from "@/components/y-form/y-upload/y_upload_video";
import y_choose_link from "@/components/shop/y_choose_link";
import y_editor from "@/components/y-form/y-editor/y_editor.vue";

const AdTypeState = {
  video:{
    value:1,
    label:"视频",
  },
  image:{
    value:2,
    label:"图片链接",
  },
  popupRichText:{
    value:3,
    label:"弹出图文框",
  }
}
export default {
  name: "ad-popup",
  components: {
    y_editor,
    y_choose_link,
    y_upload_video,
    y_upload_img,
    YLayoutBodyMenu
  },
  data() {
    return {
      adType:AdTypeState,
      form: {
        ad_type: 1,
        content:"",
        link:"",
        enable:false,
        video_height:0,
        popup_detail_use_video:false,
        popup_detail_link:"",
        popup_detail_video:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    adTypeChange(){
      this.form.content = "";
    },
    load() {
      this.$u.api.system.adPopup().then(res => {
        this.form = res;
      })
    },
    submit() {
      this.$u.api.system.adPopupEdit(this.form).then(() => {
        this.$message.success("操作成功");
      })
    }
  }
}
</script>

<style scoped>

</style>